.pricing {
	@extend %page;
	@extend %center;
	flex-direction: column;
	min-height: 0;
	// background: rgb(var(--data-color-black));
	position: relative;
	padding: 0;
	padding: 4px 0;

	@include media('<tablet') {
		padding: 0 0;
	}
	// border-radius: 0px 0px 10vh 10vh;
}

.pricing__container {
	width: 100%;
	max-width: 1485px;
	margin: 96px auto;
	z-index: z('content');
	box-shadow: $shadow-large;

	@include media('<tablet') {
		margin: 0 0;
	}
}

.pricing__block {
	width: 100%;
}

.pricing__section__heading {
	@extend %bold-48;
	margin-bottom: 32px;
}

// Main
.pricing__main {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 4px;

	@include media('<tablet') {
		// grid-template-rows: 0.5fr 1fr;
		display: flex;
		flex-direction: column;
		gap: 0px;
	}
}

.pricing__main__about {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.pricing__main__dev,
.pricing__main__des {
	background: rgb(var(--data-color-background));
}

.pricing__main__header {
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-family: $font-manrope;
	text-transform: uppercase;
	font-weight: 700;
	padding: 33px 0;
	border-bottom: 1px solid #f3f3f3;

	&--des {
		background: #4866a7;
		color: rgb(var(--data-color-white));
	}
	&--about {
		background: rgb(var(--data-color-black));
		color: rgb(var(--data-color-white));
	}

	@include media('<tablet') {
		border-top: 1px solid #f3f3f3;
	}
}
.pricing__box {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding: 0 56px;
	border-bottom: 1px solid #f3f3f3;

	.pricing__box__icon {
		flex-shrink: 0;
		max-width: 36px;
	}

	&--dev {
	}
	&--des {
		.pricing__box__icon__outer {
			stroke: #4866a7;
		}
		.pricing__box__icon__inner {
			fill: #4866a7;
		}
	}

	&--empty {
		.pricing__box__header__text {
			@extend %hidden;
		}

		@include media('<phone') {
			display: none;
		}
	}

	@include media('>tablet') {
		&--c:hover {
			cursor: pointer;
			background: hsl(221, 40%, 98%);
			.pricing__box__icon,
			.pricing__box__header__text {
				opacity: 0.7;
			}
		}
	}

	@include media('<tablet') {
		padding: 0 33px;
	}
}

.pricing__box__header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-wrap: nowrap; // is it fine?
}
.pricing__box__header__text {
	@extend %paragraph-16;
	font-size: 14px;
	margin-bottom: 0;
	padding: 33px 0;
}
.pricing__main__description {
	@extend %paragraph-16;
	min-height: 72px;

	@include media('<phone') {
		margin-bottom: 0;
	}
	padding: 33px 0;
}
.pricing__main__about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.pricing__main__wrapper {
	padding: 33px;

	&--details {
		background: rgb(var(--data-color-background));
		margin-top: 4px;

		&:last-of-type {
			height: 100%;
		}
	}

	@include media('<tablet') {
		padding: 24px;
		padding-bottom: 96px;
		padding-top: 48px;
	}
}
.contact__form__pricing {
	margin-top: 4px;
}
.pricing__main__title {
	@extend %bold-20;
	text-align: left;

	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
	&:first-of-type {
		margin-top: 0;
	}

	&--short {
		max-width: 330px;
	}
}
.pricing__main__text {
	@extend %paragraph-16;
	// margin-left: auto;
	// margin-right: auto;
}
// Left
.pricing__main__left {
	background: rgb(var(--data-color-background));
	padding: 64px 42px;

	@include media('<phone') {
		padding: 48px 32px;
	}
}

.pricing__main__price {
	display: flex;
	flex-direction: column;
	max-width: fit-content;
	margin-top: 72px;

	@include media('<phone') {
		margin-top: 64px;
	}
}
.pricing__main__price__hint {
	@extend %paragraph-16;
	align-self: flex-end;
	margin-bottom: 0;
}
.pricing__main__price__number {
	@extend %bold-48;
	font-family: $font-grotesk, Arial, Helvetica, sans-serif;
}
.pricing__main__button,
.pricing__secondary__button {
	font-size: 16px;
	max-height: 60px;
	max-width: 330px;
	margin: 50px auto;
	margin-bottom: 0;
}
.pricing__secondary__button {
	margin: 0 auto;
	margin-top: 20px;
}
.pricing .contact__form-group {
	max-width: 330px;
	margin-left: auto;
	margin-right: auto;

	&:first-of-type {
		margin-top: 20px;
	}
}
// Bottom

.pricing__type__selection {
	@extend %center;
	@extend %paragraph-16;
	margin: 0 0;
	background: rgb(var(--data-color-background));
	min-height: 92px;

	transition: background 0.6s ease, color 0.3s ease, font-weight 0.3s ease,
		font-size 0.3s ease;
	user-select: none;
	cursor: pointer;

	&.active {
		font-weight: 700;
		font-size: 20px;
		color: rgb(var(--data-color-white));
	}

	&.active[data-type='basic'] {
		background: #79bf73;
	}
	&.active[data-type='standard'] {
		// background: #8c81c5;
		background: #5776b7;
	}
	&.active[data-type='pro'] {
		background: #c6b780;
	}
}
