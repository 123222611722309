.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 700px;
	height: 9vh;
	font-family: 'Space Mono';
	text-transform: uppercase;
	font-size: 22px;
	font-weight: 700;
	letter-spacing: 0px;

	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;

	color: rgb(var(--data-color-white));
	background: rgba(var(--data-color-black), 1);
	border-radius: 8px;
	// border: 2px solid rgb(var(--data-color-black));
	box-shadow: rgba(0, 0, 0, 0.2) 8px 8px 0px;

	min-width: 16rem;
	padding: 0;
	font-family: inherit;
	font-size: 24px;

	margin-top: 5.5rem;

	// margin-left: 3.6rem;
	position: relative;
	transition: transform 0.4s ease-out, box-shadow 0.4s ease-out;

	@include media('<tablet') {
		max-width: 420px;
		font-size: 21px;
	}

	@include media('<phone') {
		font-size: 18px;
		// height: 6vh;
		// max-width: 419px;
	}
}

.btn:hover {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px;
	transform: translate(8px, 8px);
}

.btn--small {
	margin-top: 0;
	min-width: 0;
	font-size: 20px;
	box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 0px;

	font-size: 16px;
	max-height: 60px;
	max-width: 330px;
	margin: 16px auto;
	margin-bottom: 0;
}
.btn--small:hover {
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px;
	transform: translate(4px, 4px);
}

.btn__text {
}

.btn__text--inner {
	@extend %paragraph-24;
	margin: 0 0;
	font-weight: bold;
}

// .btn:hover {
// 	color: rgb(var(--data-color-black));
// 	background: rgba(var(--data-color-white), 0.8);
// 	backdrop-filter: blur(10px);
// 	// border-color: rgb(var(--data-color-black));
// }

// .btn::before {
// 	content: '';
// 	position: absolute;
// 	top: 5px;
// 	left: 5px;
// 	width: calc(100% - 10px);
// 	height: calc(100% - 10px);
// 	background: rgba(0, 0, 0, 0.3);
// 	filter: blur(13px);
// 	border-radius: 7px;
// }

.btn__text,
.btn__text-inner {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.btn__icon--inner {
	width: 28px;
	height: 28px;
	margin-left: 8px;

	@include media('<laptop') {
		width: 20px;
		height: 20px;
	}
}
.btn__text {
	flex: none;
	// background: rgb(var(--data-color-black));
	width: 100%;
	height: 100%;
	border-radius: 7px;
	transition: background 0.4s ease-out;
}

.btn--hover > .btn__text {
	background: $color-main;
}
.btn--hover > .btn:focus,
.btn--hover {
	color: #fff;
}

.btn--white {
	color: rgb(var(--data-color-black));
	background: rgb(var(--data-color-white));
	// border-color: rgb(var(--data-color-white));
	.btn__icon--inner {
		path {

			stroke: rgb(var(--data-color-black));
		}
	}
}

.btn--empty {
	border: 1px solid rgb(var(--data-color-black));
	background: transparent;
	color: rgb(var(--data-color-black));
}
.btn--disabled {
	pointer-events: none;
	cursor: not-allowed;
	transform: translate(8px, 8px);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px;

	&.btn--small {
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px;
		transform: translate(4px, 4px);
	}
}
.btn--error {
	background: #a84848;

	&:hover {
		cursor: not-allowed;
	}
}
.btn--success {
	background: #48a858;
}
