.noisy__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: inherit;

	* {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: inherit;
	}

	z-index: z('content-back');
}

.noisy__overlay__bg--color {
	background: rgb(217, 225, 242);
	z-index: z('content-back');
}

.noisy__overlay__bg {
	background-position: center;
	background-repeat: repeat;

	mix-blend-mode: color-dodge;
	opacity: 0.5;

	z-index: z('content');
}

.noisy__overlay__bg--gradient {
	--data-grad-color: 217, 225, 242;

	background: radial-gradient(
		circle,
		rgb(var(--data-grad-color), 0) 80%,
		rgb(var(--data-grad-color), 1) 100%
	);

	z-index: z('content-front');
}
.noisy__overlay__bg--gradientLinear {
	--data-grad-color: 246, 244, 242;

	background: linear-gradient(
		to top,
		rgb(var(--data-grad-color), 1),
		rgb(var(--data-grad-color), 0)
	);

	z-index: z('content-front');
}
