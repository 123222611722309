.vision {
	margin-top: 0;
	top: 0 !important;
	left: 0;
	position: relative;

	@include media('<tablet') {
		padding-top: 96px;
	}
}

.vision .section__wrapper {
	padding-top: calc(var(--data-nav-height) + 7vh);
	max-height: 1000px;
	z-index: z('content');
	position: relative;
}

.vision__description {
	&--topmargin {
		margin-top: 3.5rem;

		@include media('<phone') {
			text-align: justify;
			margin-top: 20px;
		}
	}
	@include media('<phone') {
		text-align: justify;
	}
}

.vision__gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: end;
	justify-items: center;
	justify-content: space-evenly;
	width: 100%;
	gap: 20px;
	margin-top: calc(var(--data-nav-height) + 7vh);
	padding-bottom: 24px;

	@include media('<phone') {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		text-align: justify;
		gap: 40px;
	}
}

.vision__gallery__item {
	max-width: 96px;
}

.vision__gallery__caption {
	@extend %paragraph-faded-small;
	color: #c8c8c8;
	text-align: center;
	margin-top: 32px;

	@include media('<phone') {
		margin-bottom: 0;
	}
}
