.notfound {
	@extend %page;
	flex-direction: column;
}

.notfound__wrapper {
	@extend %center;
	background: rgb(var(--data-color-background));
	flex-direction: column;
	position: relative;
}

.notfound__title {
	@extend %black-256;

	color: rgb(var(--data-color-black));
	-webkit-text-fill-color: rgb(var(--data-color-background));
	-webkit-text-stroke-width: 4px;
	-webkit-text-stroke-color: rgb(var(--data-color-black));
}
.notfound__subtitle {
	margin-top: 32px;
	@extend %bold-62;
	color: rgb(var(--data-color-black));
	-webkit-text-fill-color: rgb(var(--data-color-background));
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: rgb(var(--data-color-black));
}
.notfound__graphics {
	position: absolute;

	z-index: -1;

	&__left {
		bottom: 0;
		left: 0;
	}
	&__right {
		top: 0;
		right: 0;
	}

	@include media('<laptop') {
		display: none;
	}
}
