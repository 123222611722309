.contact__form-wrapper {
	@extend %center;
	position: relative;
	z-index: z('content');
	padding: 4vh 4vh;
	padding-bottom: 0;

	@include media('<phone') {
		padding: 0 0;
	}
}

.contact__form {
	background: rgb(var(--data-color-background));
	padding: 192px 10vh;
	width: 100%;

	@include media('<phone') {
		padding: 48px 24px;
	}
}

.contact__form-group {
	position: relative;
	width: 100%;
	margin-top: 36px;

	&:first-child {
		margin-top: 0;
	}

	.contact__form-input {
		@extend %paragraph-16;
		margin-bottom: 0;
		width: inherit;
		max-width: 100%;
		border: none;
		border-bottom: 1px solid rgb(var(--data-color-black));
		padding: 8px 0;
		background: none;
		height: 100%;

		&:focus,
		&:focus-visible,
		&--filled {
			outline: none;

			& + .contact__form-label {
				transform: translateY(-80%) scale(0.5);
			}
		}

		@include media('<phone') {
			font-size: 12px;
		}
	}

	.contact__form-textarea {
		@extend %paragraph-16;

		width: 100%;
		max-width: 100%;
		border: none;
		border-bottom: 1px solid rgb(var(--data-color-black));
		margin: 0 0;

		min-height: 7.7rem;
		resize: vertical;

		&:focus,
		&:focus-visible,
		&--filled {
			outline: none;

			& + .contact__form-label {
				transform: translateY(-100%) scale(0.5);
			}
		}
		@include media('<phone') {
			font-size: 12px;
		}
	}
}

.contact__form-group--doublemargin {
	margin-top: 72px;
}

.contact__form-label {
	@extend %paragraph-16;

	pointer-events: none;
	color: rgb(var(--data-color-black));
	position: absolute;
	left: 0;
	top: 16px;
	// transform: translateY(-30%);
	opacity: 1;
	transition: transform 0.5s ease;
	transform-origin: top left;

	&--textarea {
		top: 0;
	}

	@include media('<phone') {
		font-size: 12px;
	}
}
.contact__form-label--regular {
	position: relative;
	top: 0;
	transform: translateY(0);
}
// test
.checkbox__wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}
.checkbox__item {
	display: flex;
	align-items: center;
	margin-top: 8px;
}
.checkbox__label {
	@extend %paragraph-16;
	margin-left: 12px;
	margin-bottom: 0;
}
.checkbox__input {
	height: 18px;
	width: 18px;
	border-radius: 0;
	border: 1.5px solid;
	border-color: rgba(var(--data-color-black), 0.5);

	-webkit-appearance: none;
	appearance: none;
	background: rgb(var(--data-color-background));
	margin: 0;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 0;
		background: rgba(var(--data-color-black), 0);

		transform-origin: bottom left;
		// clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		margin: 4px;
	}

	&:checked {
		// background: rgba(var(--data-color-black), 1);
		border-color: rgba(var(--data-color-black), 1);
	}

	&:checked:before {
		background: rgba(var(--data-color-black), 1);
	}
}

.contact__form-button {
	height: 5vh;
	font-size: 19px;
	max-width: 100% !important;
}
.contact__privacynotice {
	@extend %paragraph-16;
	display: block;
	text-align: center;
	width: 100%;
	max-width: 100%;
	margin-top: 24px;
	color: rgb(var(--data-color-black));

	a {
		@extend %link;
		margin-left: 4px;
	}
}
