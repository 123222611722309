%extrabold-128 {
	font-family: $font-manrope;
	font-weight: 900;
	font-size: 128px;
	letter-spacing: -4px;
	text-transform: uppercase;

	@include media('<tablet') {
		font-size: 96px;
		letter-spacing: -2px;
	}

	@include media('<phone') {
		font-size: 72px;
		letter-spacing: -2px;
	}
}

%bold-62 {
	font-family: $font-manrope;
	font-weight: 700;
	font-size: 62px;
	line-height: 48px;
	letter-spacing: -2px;
	// text-transform: uppercase;

	@include media('<laptop') {
		font-size: 48px;
	}
}

%bold-48 {
	font-family: $font-manrope;
	font-weight: 700;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -2px;
	text-transform: uppercase;

	& strong {
		font-style: italic;
	}

	@include media('<laptop') {
		font-size: 32px;
		line-height: 32px;
	}
}
%bold-20 {
	font-family: $font-manrope;
	font-weight: 700;
	font-size: 20px;
	line-height: 26.5px;
	margin-bottom: 20px;
	text-align: center;

	@include media('<phone') {
		margin-bottom: 12px;
	}
}
%paragraph-24 {
	font-family: $font-manrope;
	font-weight: 400;
	font-size: 24px;
	line-height: 45.5px;

	max-width: 700px;
	margin-bottom: 45.5px;

	@include media('<laptop') {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

%paragraph-16 {
	font-family: $font-manrope;
	font-weight: 400;
	font-size: 16px;
	line-height: 22.5px;

	max-width: 700px;
	margin-bottom: 22.5px;

	@include media('<laptop') {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

%paragraph-20 {
	font-family: $font-manrope;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;

	max-width: 700px;
	margin-bottom: 32px;

	@include media('<laptop') {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 24px;
	}
}
%paragraph-faded {
	@extend %paragraph-24;
	color: #d1cfcd;
}

%paragraph-faded-small {
	color: #d1cfcd;
	font-family: $font-manrope;
	font-weight: 400;
	font-size: 16px;
	line-height: 22.5px;

	max-width: 700px;
	margin-bottom: 22.5px;

	@include media('<laptop') {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
	}
}

%light-16 {
	@extend %paragraph-16;
	font-weight: 300;
	color: #d1cfcd;
	// font-family: $font-manrope;
	@include media('<laptop') {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
	}
}

%black-256 {
	font-family: $font-manrope;
	font-weight: 900;
	letter-spacing: -0.2rem;
	font-size: 256px;
	text-transform: uppercase;
}
