.brandiden {
	flex-direction: column;
	padding: 0 0;
	padding-top: 0;

	.section__wrapper {
		z-index: z('content');
	}

	@include media('<phone') {
		padding-bottom: 50px;
	}
}

.brandiden__top__wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;

	width: 100%;
	height: inherit;

	color: rgb(var(--data-color-black));
	padding: var(--data-page-pad);
}
.brandiden__top__description {
	align-self: flex-start;
	max-width: 850px;
}
.brandiden__list {
	@extend %center;

	width: 100%;
	max-width: 100%;
	flex-direction: column;
	height: inherit;
	width: 100%;
	z-index: z('content');
	position: relative;
	padding-bottom: 96px;
}

// Item
.brandiden__item__wrapper {
	--data-time: 0.98s;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	mix-blend-mode: screen;
	position: relative;
	// background: rgb(var(--data-color-background));
	z-index: 1;

	width: inherit;
	border-bottom: 1px solid rgb(var(--data-color-black));
	text-align: left;
	padding: var(--data-page-pad);
	padding-top: 0;
	padding-bottom: 0;
	overflow: hidden;
	transition-delay: 0.2s;

	&:first-of-type {
		border-top: 1px solid rgb(var(--data-color-black));
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgb(var(--data-color-black));
		z-index: -1;
		transform: scaleY(0);
		transition: transform var(--data-time), color var(--data-time);
		transition-timing-function: $ease-out-expo;
		transform-origin: bottom;
		// transition-delay: 0.2s;
		
	}
	transition: color var(--data-time);
	transition-timing-function: $ease-out-expo;

	svg {
		transition: fill var(--data-time);
		transition-timing-function: $ease-out-expo;
	}

	@include media('>tablet') {
		&:hover {
			&:before {
				transform-origin: top;
				transform: scale(1);
				transition-delay: 0s;
			}
			color: rgb(var(--data-color-white));
			// color: rgb(var(--data-color-white));

			svg {
				fill: rgb(var(--data-color-white));
			}
		}
	}
}
.brandiden__item {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 1500px;
	margin: auto;
	// text-align: left;
	// padding-left: 12%;
	z-index: 1;
	mix-blend-mode: difference;

	&:hover {
		// .brandiden__item__title {
		// 	font-weight: 700;
		// }
	}


}
.brandiden__icon {
	width: 30px;
	height: 30px;
}
.brandiden__item__title {
	@extend %paragraph-20;
	padding: 24px;
	height: 100%;
	display: block;
	margin-bottom: 0;
	padding-right: 10px;
}
.brandiden__preview {
	max-height: 400px;
	height: 100%;
	width: 100%;
	padding: 24px;

	img {
		border-radius: 24px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include media('<phone') {
		padding: 0;
	}
}
