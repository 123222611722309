footer {
	width: 100%;
	position: relative;
	background: rgb(var(--data-color-black));
	z-index: z('content');
}
.footer__links-container {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	justify-items: center;
	max-width: 1500px;
	margin: auto;
	align-items: center;
	padding: 0 10rem;
	padding-top: 192px;
	padding-bottom: 0;
	position: relative;
	z-index: z('content');
	color: rgb(var(--data-color-white));
	background: rgb(var(--data-color-black));

	@include media('<tablet') {
		padding: 20px;
		padding-top: 78px;
		// min-height: 53vh;

		min-height: 22vh;
		padding-bottom: 0;
	}

	@include media('<phone') {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;
	}
}
.footer__info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1500px;
	margin: auto;
	padding: 0 10rem;
	padding-bottom: 48px;
	padding-top: 48px;
	margin-top: 64px;
	border-top: 1px solid rgba(211, 211, 211, 0.1);

	z-index: z('content');

	@include media('<tablet') {
		padding: 20px;
	}
}
.footer__info__text {
	@extend %paragraph-16;
	text-align: center;
	max-width: 310px;
	margin-bottom: 0;
	color: rgb(var(--data-color-white));

	@include media('<phone') {
		text-align: left;

		margin-right: auto;
	}
}
.footer__top {
	// background: rgb(var(--data-color-white));
	background: rgb(246, 244, 242);
	height: 36px;
	width: 100%;
	border-radius: 0 0 10vh 10vh;
	position: absolute;
	top: -1px;
	left: 0;
	z-index: z('content-front');
	overflow: hidden;
}
.footer__item {
	@extend %paragraph-16;

	display: flex;
	flex-direction: column;
	line-height: 24px;
	margin-bottom: 0;
	padding-bottom: 7px;
	// border-bottom: 1px solid rgb(var(--data-color-white));

	@include media('<phone') {
		margin: 16px 0;
	}
}
.footer__text {
	font-weight: bold;
}
.footer__link {
	// @extend %link--hidden;
}
.footer__link:hover {
	opacity: 0.8;
}
footer .signature {
	position: absolute;
	bottom: 30px;
	height: 300px;
	left: 50%;
	transform: translate(-50%, 0%) rotate(-10deg);
	opacity: 0.22;

	@include media('<phone') {
		height: 200px;
	}
}
