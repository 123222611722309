main {
	font-size: 3rem;
	width: 100%;
	height: auto;
	position: relative;
}
.cover__scrolldown-wrapper {
	@extend %center;
	flex-direction: column;
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translateX(-50%);
	animation: scrolldown 5s infinite ease-in-out;
}
@keyframes scrolldown {
	0% {
		opacity: 0;
	}
	33% {
		opacity: 1;
	}
	66% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
.cover__scrolldown__text {
	@extend %bold-20;

	// text-transform: uppercase;
}
.cover__container {
}
.cover__scrolldown__svg {
	transform: rotate(90deg);
	animation-delay: 2s;
}
.cover {
	opacity: 1;
	visibility: visible;

	width: 100%;

	height: 100%;
	// min-height: 200vh;

	// background-image: url('../shared/images/cover.jpg');
	background-position: left;
	background-size: cover;
	z-index: z('content');
	// position: absolute;
	// top: 0;
	// left: 0;

	color: rgb(var(--data-color-white));
	padding: 0 0;
	// @include media('<tablet') {
	// 	padding: 2rem 40px;
	// }
}
.cover__wrapper {
	opacity: 1;
	visibility: visible;

	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;

	width: 100%;
	height: 100%;
	max-height: 100vh;
	bottom: 0;

	// padding: 15rem 0;

	min-height: 100vh;
	position: fixed;
	z-index: z('content');
}
.cover__logo {
	position: absolute;
	top: 1.75rem;
	left: 6.5rem;
	max-width: 12.8rem;
	max-height: 12.8rem;

	@include media('<tablet') {
		display: none;
	}
}
.cover__title {
	@extend %bold-48;

	@include media('<tablet') {
		max-width: 500px;
	}

	@include media('<phone') {
		font-size: 22px;
		line-height: 22px;
	}
}

.cover__bottom {
	position: fixed;
	bottom: 0;
	width: 100%;
	min-height: 10vh;
	padding: 9vh 12vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(var(--data-color-background));
	color: rgb(var(--data-color-black));

	@include media('<tablet') {
		padding: 4vh 6vh;
	}
	@include media('<phone') {
		padding: 2vh 3vh;
	}
}
.cover__scrolldown {
	@extend %paragraph-24;

	font-weight: 700;
	text-transform: uppercase;
	margin: 0 0;
	text-align: right;
	line-height: 100%;
	min-width: 96px;
	aspect-ratio: 1/1;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	position: relative;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 50%;
		transform: scale(0);
		transition: transform 0.7s ease-in-out;
		content: '';
		width: 100%;
		height: 100%;
		background: rgb(var(--data-color-black));
		opacity: 0.09;
	}

	&:hover {
		&::before {
			transform: scale(1.5);
		}
	}

	&:active {
		&::before {
			background: #4866a7;
		}
	}

	@include media('<phone') {
		font-size: 12px;

		&::before {
			background: #4866a7;
			transform: scale(1);
		}
		&:hover {
			&::before {
				background: #4866a7;
				transform: scale(1);
			}
		}
	}
}
