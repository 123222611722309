.mdesign .section__wrapper {
	z-index: z('content');
	position: relative;
}

.mdesign__gallery {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 48px;
	gap: 1vh;
}

.mdesign__gallery__item {
	cursor: pointer;
	width: 100%;
	height: auto;
	margin-top: 1vh;
	overflow: hidden;

	// &:first-of-type {
	// 	margin-top: 0;
	// }

	img {
		width: 100%;
		height: 100%;
		transform: scale(1);
		transition: transform 1s $ease-out-expo;
	}

	&:hover img {
		transform: scale(1.1);
	}
}

.mdesign__gallery-right, .mdesign__gallery-left {
	display: flex;
	flex-direction: column;
}