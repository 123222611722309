@import './../node_modules/@fontsource/raleway/index.css';
@import './../node_modules/@fontsource/raleway/900.css';
@import './../node_modules/@fontsource/raleway/800.css';
@import './../node_modules/@fontsource/raleway/700.css';

@import './../node_modules/@fontsource/space-mono/index.css';
@import './../node_modules/@fontsource/space-mono/400.css';
@import './../node_modules/@fontsource/space-mono/700.css';

@import './../node_modules/@fontsource/space-grotesk/index.css';
@import './../node_modules/@fontsource/space-grotesk/400.css';
@import './../node_modules/@fontsource/space-grotesk/700.css';

@import './../node_modules/@fontsource/manrope/400.css';
@import './../node_modules/@fontsource/manrope/700.css';

@font-face {
	font-family: 'Halenoir Light';
	src: url('fonts/HalenoirCompactText-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Halenoir Black';
	src: url('fonts/HalenoirCompactText-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}