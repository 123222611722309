nav {
	position: fixed;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: z('navigation');
	padding: 0 0;
	background: rgb(var(--data-color-background));

	transform: translateY(0%);

	@include media('<phone') {
		padding: 0 0;
	}
}

.nav__wrapper {
	@extend %center;

	width: 100%;
	padding: 0 0;
	z-index: 10;
	position: relative;
	justify-content: space-between;
	height: var(--data-nav-height);

	@include media('<tablet') {
		padding: 0 7vw;
		min-height: 0rem;
		justify-content: center;
	}

	@include media('<=1024px', '<=1366px', 'retina2x') {
		padding: 0 7vw;
		min-height: 0rem;
		justify-content: center;
	}
	@include media('<phone') {
		height: var(--data-nav-height);
		padding: 0 24px;
	}
}
.nav__center__desktop {
	display: flex;
	height: inherit;

	@include media('>=laptop') {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		top: 0;
	}

	@include media('<=1024px', '<=1366px', 'retina2x') {
		display: none;
	}

	@include media('<tablet') {
		display: none;
	}
}
.nav__desktop__link {
	@extend %center;
	height: inherit;

	position: relative;

	user-select: none;
	-webkit-user-drag: none;

	transition: all 0.3s ease;

	.nav__desktop__link--inner {
		height: 100%;
		width: 100%;
		background: rgb(var(--data-color-white));
		color: rgb(var(--data-color-black));
	}

	&--inner {
		@extend %center;
		padding: 0 32px;
		height: inherit;
		min-width: 170px;
	}

	&:hover,
	&.active {
		.nav__desktop__link--inner:first-of-type {
			background: #1f1f1f;
			color: rgb(var(--data-color-white));
		}
	}

	&--sublinks:hover {
		.nav__desktop__sublinks {
			transform: translateY(0%);
			visibility: visible;
			opacity: 1;
		}
	}
	&--sublinks {
		z-index: 1;
	}
}
.nav__desktop__link--sublinks {
	@extend %center;
	height: inherit;
	position: relative;
}
.nav__desktop__sublinks {
	@extend %hidden;
	transform: translateY(-200%);
	position: absolute;
	top: 100%;
	left: 0;
	height: inherit;
	width: 100%;
	transition: opacity 0.3s ease, transform 0.3s ease;

	z-index: -1;
}
.nav__right__desktop {
	@extend %center;
	height: inherit;
	position: relative;
	padding: 0 32px;
	background: #1f1f1f;
	color: rgb(var(--data-color-white));
	width: 320px;
	transition: all 0.3s ease;

	user-select: none;
	-webkit-user-drag: none;
	&:hover {
		background: #4866a7;
		color: rgb(var(--data-color-white));
	}

	@include media('<=1024px', '<=1366px', 'retina2x') {
		display: none;
	}

	@include media('<tablet') {
		display: none;
	}
}
.nav__desktop__button {
	@extend %center;
}
.nav__desktop__svg {
	margin-left: 48px;
}
.nav__left {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;

	@include media('<laptop') {
		width: 100%;
	}

	@include media('<tablet') {
		width: 20%;
		height: inherit;
	}
	@include media('>tablet') {
		padding-left: 20px;
	}
}
.nav__logo__img {
}
.nav__logo {
	position: relative;
	width: 100%;
	max-width: 108px;

	@include media('<laptop') {
		max-width: 74px;
	}
}
.nav__logo--overlay {
	position: absolute;
	top: 0;
	left: 0;
	// animation: reveal 2s forwards infinite;
	animation-delay: 1s;
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}
.nav__right__mobile {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	width: 80%;

	@include media('>tablet') {
		display: none;
	}

	@include media('<=1024px', '<=1366px', 'retina2x') {
		display: flex;
	}
}
.nav__email__item {
	@extend %paragraph-16;

	margin-right: 15%;
	line-height: 22px;
	margin-bottom: 0;

	display: flex;
	flex-direction: column;

	@include media('<laptop') {
		font-size: 12px !important;
		line-height: 16px;
	}

	@include media('<phone') {
		display: none;
	}
}
.nav__email__title {
}
.nav__email__link {
	@extend %link--hidden;

	&:hover {
		color: rgba(var(--data-color-black), 0.8);
	}

	&.dark:hover {
		color: rgba(var(--data-color-white), 0.8) !important;
	}
}

// .nav__menu {
// 	width: 48px;
// 	height: 48px;
// 	background: grey;
// }
.nav__menu {
	width: 48px;
	height: 48px;
	position: relative;
	cursor: pointer;
	caret-color: transparent;
}

.nav__menu__line {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	height: 2px;
	margin: -1px 0 0 -40%; /* Half the height and half the width to center */
	border-radius: 10px;
	background-color: #111;
}

/* Closed state */
.nav__menu__line:first-child {
	top: 35%;
}

.nav__menu__line:last-child {
	top: 65%;
}

// .nav__menu div:nth-child(2) {
// 	width: 60%;
// }
.nav__content {
	visibility: hidden;
	width: 100%;
	padding: 0 7vw;
	height: 102vh;
	position: fixed;
	top: 0;
	left: 0;

	background: rgb(var(--data-color-black));
	color: rgb(var(--data-color-white));

	z-index: 7;
}
.nav__content__container {
	@extend %center;
	flex-direction: column;
	width: inherit;
	height: inherit;
}
.nav__content__link {
	@extend %bold-62;

	position: relative;
	overflow: hidden;
	width: 330px;
	height: 64px;
	margin: 40px 0;

	@include media('<laptop') {
		margin: 20px 0;
	}

	@include media('<phone') {
		width: 100%;
	}
	color: rgb(var(--data-color-white));
}
.nav__content__sublinks {
	width: 330px;
	margin: 40px 0;
	margin-top: 0;
	@include media('<laptop') {
		margin: 20px 0;
		margin-top: 0;
	}
	@include media('<phone') {
		width: 100%;
	}
	color: rgb(var(--data-color-white));
}
.nav__content__sublink {
	@extend %bold-48;
	@extend %center;
	flex-direction: column;
	width: inherit;
	height: 48px;

	margin: 10px 0;

	&:nth-last-of-type() {
		margin-bottom: 20px;
	}

	& .nav__content__link--inner {
		padding-left: 12px;
	}
}
.nav__content__link--inner {
	position: absolute;
	top: 0;
	left: 0;
	width: inherit;
	height: inherit;
}
// .nav__content__link:hover {
// 	color: rgb(var(--data-color-white));

// 	-webkit-text-fill-color: rgb(var(--data-color-black));
// 	-webkit-text-stroke-width: 1px;
// 	-webkit-text-stroke-color: rgb(var(--data-color-white));
// }

@keyframes gradient {
	0% {
		background-position: 0%;
	}
	100% {
		background-position: 100%;
	}
}

.nav__content__link .active {
	color: #f6f4f2;

	// -webkit-text-stroke-width: 1px;
	// -webkit-text-stroke-color: rgb(var(--data-color-white));
	// cursor: default;

	background: linear-gradient(
		270deg,
		#de9751,
		#cc1709,
		hsl(13, 10%, 75%),
		#f6f4f2,
		#de9751,
		#cc1709
	);
	background-size: 500% 100%;
	animation: gradient 7s linear infinite;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
