$color-white: '255, 255, 255';

$color-background: '255, 255, 255';
$color-black: '12, 11, 14';
$color-main:  '72, 102, 167';

$ease-in-out: cubic-bezier(0, 0, 0.975, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$font-raleway: 'Raleway', sans-serif;

$font-grotesk: 'Space Grotesk';
$font-mono: 'Space Mono';
$font-manrope: 'Manrope', Helvetica, Arial, sans-serif;

$shadow-large: rgba(0, 0, 0, 0.1) 0px 10px 50px;
$shadow-small: rgba(0, 0, 0, 0.1) 0px 10px 20px;
.test {
	color: rgb(12, 11, 14);
	color: rgb(31, 31, 31);
}
$z-indexes: (
	'popup',
	'preloader',
	'cookies',
	'navigation',
	'content-front',
	'content',
	'content-back',
	'canvas'
);
