.cookies {
	position: fixed;
	right: 0;
	bottom: 0;
	margin-bottom: 8px;
	margin-right: 8px;
	z-index: z('cookies');
	opacity: 1;
	visibility: visible;

	@include media('<tablet') {
		margin: 0 0;
		width: 100%;
	}
}

.cookies__wrapper {
	background: rgba(var(--data-color-white), 0.9);
	backdrop-filter: blur(40px);
	color: rgb(var(--data-color-black));
	padding: 28px 24px;
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	max-width: 345px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	@include media('<tablet') {
		border-radius: 8px 8px 0 0;
		max-width: 100%;
		padding: 20px 30px;
		align-items: left;
		justify-content: left;
	}
}

.cookies__header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.cookies__icon {
	max-width: 24px;
	max-height: 24px;
}
.cookies__title {
	@extend %bold-20;

	margin-bottom: 0;
	text-transform: none;
	margin-left: 8px;
}
.cookies__description {
	@extend %paragraph-16;
	margin-top: 13px;

	margin-bottom: 24px;

	span {
		display: block;
		margin-top: 16px;
	}

	@include media('<tablet') {
		text-align: center;
	}
	
	@include media('<phone') {
		font-size: 12px;
		text-align: justify;
	}
}
.cookies__description__link {
	@extend %link--hidden;
}
.cookies__buttons {
	display: flex;
	flex-direction: column;

	.btn {
		@include media('<phone') {
			font-size: 14px;
			max-height: 48px;
			max-width: 100%;
		}
	}
}



.cookies__button {
	margin-left: 36px;
	transition: all 0.3s ease-out;

	&:first-child {
		margin-left: 0;
	}
}

// .cookies__button--accept {
// 	background: rgb(var(--data-color-black));
// 	color: rgb(var(--data-color-stone));

// 	&:hover {
// 		color: rgb(var(--data-color-black));
// 		background: #64d8a0;
// 		border-color: #64d8a0;
// 	}

// 	// background: rgb(var(--data-color-cyan));
// 	// color: rgb(var(--data-color-stone));
// 	// border-color: rgb(var(--data-color-cyan));

// 	// &:hover {
// 	// 	background: rgb(var(--data-color-cyan));
// 	// 	color: rgb(var(--data-color-black));
// 	// }
// }

// .cookies__button--leave {
// 	background: transparent;
// 	color: rgb(var(--data-color-black));

// 	&:hover {
// 		background: rgb(var(--data-color-black));
// 		color: rgb(var(--data-color-stone));
// 	}
// }

.cookies__button--settings {
	background: rgb(var(--data-color-black));
	color: rgb(var(--data-color-stone));

	&:hover {
		background: rgb(var(--data-color-cyan));
		color: rgb(var(--data-color-black));
		border-color: rgb(var(--data-color-cyan));
	}
}
.cookies__settings__wrapper {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 2.6rem;

	@include media('<tablet') {
		margin-bottom: 24px;
	}
}
