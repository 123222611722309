.graphicdes-1 {
	display: block;
	min-height: 50vh;
	width: 100%;
	background: rgb(var(--data-color-background));
	position: relative;
	z-index: z('content');
	margin-bottom: 2vh;
}

.graphicdes-2 {
	display: block;
	min-height: 50vh;
	width: 100%;
	background: #1f1f1f;
}
