.websites {
	@include media('<tablet') {
		padding-top: 64px;
	}
}

.projects__wrapper {
	@extend %center;
	flex-direction: column;
	height: inherit;
	width: 100%;
	max-width: 1500px;
	padding-top: 96px;
	margin: auto;
	position: relative;
	z-index: z('content');
}

.projects__title {
	width: 100%;
	text-align: center;
	margin-left: 0;

	@include media('<laptop') {
		text-align: center;
	}
}

.projects__item {
	height: inherit;
	width: 100%;
	min-height: 70vh;
	margin-top: 20vh;

	&:last-of-type {
		margin-bottom: 20vh;
	}

	@include media('<laptop') {
		margin-top: 10vh;
	}

	// @include media('<phone') {
	// 	margin-top: 18vh;
	// }

	&:first-child {
		margin-top: 7vh;
	}
}

// Image
.projects__figure {
	@extend %center;
	align-items: flex-end;
	padding-bottom: 28px;
	position: relative;
	margin-top: 32px;
	border-radius: 20px;
	height: inherit;
	width: inherit;
	min-height: 490px;

	box-shadow: rgba(17, 12, 46, 0.1) 0px 12px 40px 0px;

	@include media('>desktop') {
		min-height: 700px;
	}
}

.projects__image {
	border-radius: inherit;
	object-fit: cover;
	height: inherit;
	width: inherit;
	position: absolute;
	top: 0;
	left: 0;
}

.projects__image__overlay {
	background: rgba(0, 0, 0, 0.12);
	border-radius: inherit;
	height: inherit;
	width: inherit;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.projects__btn {
	@extend %paragraph-24;
	font-weight: bold;
	height: auto;

	margin: 0;

	// min-width: 300px;
	// min-height: 72px;

	z-index: 2;

	padding: 20px 82px;

	margin: 0 0;
	// transform: translateX(-50%);

	// &:hover {
	// 	transform: translateX(-50%);
	// }

	@include media('<phone') {
		border-radius: 0;
		width: 100%;
		padding: 20px 40px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px;

		// &:hover {
		// 	transform: translateX(-50%);
		// }
	}
}

// Info
.projects__info__text {
	@extend %paragraph-20;
	margin-bottom: 0;
	height: 100%;
	margin-left: 30px;
	max-width: 100%;

	& strong {
		font-weight: bold;
	}
}

.projects__info {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-top: 50px;

	&:last-of-type {
		margin-bottom: 70px;
	}

	padding: 0 24px;

	width: 100%;

	@include media('<laptop') {
		margin-top: 40px;
		&:last-of-type {
			margin-bottom: 55px;
		}
	}
}

.projects__info__icon {
	@extend %center;

	min-width: 28px;
	max-width: 28px;

	@include media('<laptop') {
		min-width: 24px;
		max-width: 24px;
	}
}
.projects__info__description {
	// @extend %paragraph-24;
	@extend %paragraph-20;
	max-width: 100%;

	a {
		color: #4866a7;

		&:hover {
			color: #de9751;
		}
	}
}
