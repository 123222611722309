*,
*:after,
*:before {
	box-sizing: border-box;
}

:root {
	--data-color-black: #{$color-black};
	--data-color-white: #{$color-white};
	--data-color-background: #{$color-background};
	--data-page-pad: 4rem 12%;
	--data-nav-height: 96px;

	@include media('<tablet') {
		--data-page-pad: 4rem 7%;
	}
	@include media('<phone') {
		--data-nav-height: 64px;
		--data-page-pad: 4rem 24px;
	}
}

html {
	@extend %cover;

	font-family: 'Manrope', Arial, Helvetica, sans-serif;
	// font-size: calc(100vw / 1920 * 10);
	line-height: 1;

	color: rgb(var(--data-color-black));
	background: rgb(var(--data-color-black));
	// overflow: hidden;

	overscroll-behavior-x: none;

	cursor: default;

	@include media('<tablet') {
		font-size: calc(100vw / 343 * 10);
		overflow: auto;
		overflow-x: hidden;
	}
}

body {
	overscroll-behavior-x: none;
	background: rgb(var(--data-color-black));
	width: 100%;
	position: relative;
	// overflow: hidden;

	@include media('>laptop') {
		overflow: hidden;
	}
	-webkit-touch-callout: none;
}

a {
	text-decoration: none;
	color: inherit;
	outline: none;
}

button {
	background: none;
	border: none;
	color: inherit;
	cursor: pointer;
	outline: none;
}

h1 {
	font-weight: 900;
	font-size: 6rem;
}

p {
	font-weight: 400;
	font-size: 1.35rem;
}

img {
	pointer-events: none;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	max-width: 100%;
	height: auto;
}

.content {
	position: relative;
}

// .refresh {
// 	touch-action: none;
// }

section {
	@extend %page;
	@extend %center;

	color: rgb(var(--data-color-black));

	padding: var(--data-page-pad);
}

.section__wrapper {
	@extend %center;
	flex-direction: column;

	height: inherit;
	width: 100%;

	max-width: 1500px;
	margin: auto;
}

.section__title {
	@extend %bold-62;
	width: 100%;
	text-align: left;
	margin-left: 12rem;
	margin-bottom: 6px;

	@include media('<laptop') {
		margin-left: 36px;
	}

	@include media('<phone') {
		margin-left: 0px;
	}
}
.section__subtitle {
	@extend %paragraph-faded;
	width: 100%;
	max-width: 100%;
	text-align: left;
	margin-left: 12rem;

	@include media('<phone') {
		margin-left: 0px;
	}
}
.section__description {
	@extend %paragraph-20;
	// @extend %paragraph-24;

	// max-width: 520px;
	max-width: 700px;
	text-align: justify;
	padding: 24px;
	padding-bottom: 0;
	margin-bottom: 0;
	width: 100%;

	@include media('<phone') {
		padding: 0;
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.section__description__wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include media('<phone') {
		grid-template-columns: none;
	}
}
