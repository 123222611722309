.dreams {
	@extend %page;
	@extend %center;
	flex-direction: column;
	min-height: fit-content;
	// background: rgb(var(--data-color-background));
	background: rgb(var(--data-color-background));
	padding: 0 0;
}
.dreams__top_container {
	width: 100%;
	min-height: 50vh;
	padding: var(--data-page-pad);
	background-size: cover;
	background-position: center;

	@include media('<phone') {
		padding-top: 10vh;
		padding-bottom: 10vh;
	}
}
.dreams__bot_container {
	background-position: center;
	background-size: cover;

	display: grid;
	gap: 96px;

	align-items: center;
	justify-items: center;

	width: 100%;
	min-height: 50vh;
	padding: var(--data-page-pad);

	@include media('<phone') {
		grid-template-rows: repeat(3, 1fr);

		padding-top: 10vh;
		padding-bottom: 10vh;
	}
	@include media('>phone') {
		grid-template-columns: repeat(3, 1fr);
	}
}
.dreams__card {
	@extend %center;

	flex-direction: column;
	max-width: 350px;
}
.dreams__card__figure {
	@extend %center;
	position: relative;
	margin-bottom: 24px;
}

@keyframes snowflakeAnimation {
	0% {
		stroke-dashoffset: -100;
	}
	5% {
		stroke-dashoffset: -100;
	}
	50% {
		stroke-dashoffset: 0;
	}
	95% {
		stroke-dashoffset: 100;
	}
	100% {
		stroke-dashoffset: 100;
	}
}
@keyframes snowflakeWrapperAnimation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rectAnimation {
	0% {
		transform: scale(0);
		transform-origin: left bottom;
		transform-timing-function: ease-in;
	}
	50% {
		transform: scale(1);
		transform-origin: left bottom;
		transform-timing-function: ease-in;
	}

	51% {
		transform: scale(1);
		transform-origin: right top;
		transform-timing-function: ease-out;
	}
	100% {
		transform: scale(0);
		transform-origin: right top;
		transform-timing-function: ease-out;
	}
}
@keyframes starAnimation {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0.1;
	}
	40% {
		opacity: 0.6;
	}
	60% {
		opacity: 0;
	}
	80% {
		opacity: 0.9;
	}
	100% {
		opacity: 0;
	}
}
@keyframes diamondAnimation {
	0% {
		opacity: 1;
	}
	60% {
		opacity: 1;
	}
	70% {
		opacity: 0;
	}
	90% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
.dreams__card__figure--snowflake {
	transform: rotate(0deg);
	transform-origin: center center;
	// animation: snowflakeWrapperAnimation 5s infinite linear;
}
#dreams__snowflake {
	stroke-dasharray: 100;
	stroke-dashoffset: 0;
	animation: snowflakeAnimation 10s infinite linear;
}
#dreams__rect--empty {
	position: relative;
	left: 0;
	top: 0;
}

#dreams__rect--filled {
	position: absolute;
	left: 0;
	top: 0;
	transform-origin: left bottom;
	transform: scale(0);
	animation: rectAnimation 3s infinite ease;
}

#dreams__star1,
#dreams__star2,
#dreams__star3 {
	position: absolute;
	transform-origin: center;
	animation: starAnimation 6s infinite;
}
#dreams__diamond {
	transform-origin: center;
	animation: diamondAnimation 10s infinite ease;
}
#dreams__star1 {
	left: -20px;
	top: -10px;
	animation-delay: 2s;
}
#dreams__star2 {
	left: -2px;
	bottom: 2px;
	animation-delay: 0s;
}
#dreams__star3 {
	right: -12px;
	top: -6px;
	animation-delay: 3s;
}
.dreams__card__title {
	@extend %bold-20;
}
.dreams__card__description {
	@extend %paragraph-16;
	text-align: center;
}
