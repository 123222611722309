.get__started {
	display: flex;
	width: max-content;
	position: absolute;
	left: 0;
	bottom: 0;
	background: rgb(246, 244, 242);
	transition: background 0.5s ease;
}
// .get__started:hover {
// 	background: rgb(var(--data-color-black));
// }
// .get__started:hover > .get__started__flex span {
// 	// background: rgb(var(--data-color-white));
// 	color: rgb(var(--data-color-white));

// 	// &:nth-child(4n + 3),
// 	// &:nth-child(4n + 4) {
// 	// 	-webkit-text-fill-color: rgb(var(--data-color-black));
// 	// 	-webkit-text-stroke-width: 1.2px;
// 	// 	-webkit-text-stroke-color: rgb(var(--data-color-white));
// 	// }
// }
.get__started__flex {
}
.get__started__flex span {
	color: rgb(var(--data-color-black));
	margin-left: 20px;
	// @extend %bold-48;
	// font-family: 'Halenoir Light';
	font-family: 'Manrope';
	font-weight: 300;
	font-size: 36px;
	text-transform: uppercase;
	letter-spacing: 2px;
	transition: all 0.5s ease;

	&:nth-child(4n + 2) {
		margin-left: 9px;
	}
	&:nth-child(4n + 4) {
		margin-left: 9px;
	}

	@include media('<phone') {
		font-size: 24px;
		letter-spacing: 4px;
		margin-left: 12px;

		&:nth-child(4n + 2) {
			margin-left: 6px;
		}
		&:nth-child(4n + 4) {
			margin-left: 6px;
		}
	}

	// &:nth-child(4n + 3),
	// &:nth-child(4n + 4) {
	// 	-webkit-text-fill-color: rgb(var(--data-color-background));
	// 	-webkit-text-stroke-width: 1.2px;
	// 	-webkit-text-stroke-color: rgb(var(--data-color-black));
	// }
}
