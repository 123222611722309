.about {
	@extend %page;
	@extend %center;
	flex-direction: column;
	min-height: fit-content;
	background: rgb(var(--data-color-background));
	padding-top: 10vh;
}
.about__container {
	@extend %center;

	flex: 1;
	flex-direction: column;

	width: 100%;
	// max-width: 700px;
	// margin: auto;
	height: fit-content;
	min-height: inherit;

	padding: 0 7vw;
	box-sizing: border-box;
	z-index: z('content-front');
	@include media('<laptop') {
		// justify-content: flex-start;
		padding: 0 3vw;
	}
	@include media('<phone') {
		width: 100%;
		padding: 0 0;
	}
}
.about__left {
}
.about__text {
	@extend %paragraph-20;
	text-align: center;

	@include media('<phone') {
		width: 100%;
	}
}

.about__right {
}

.about__top__title {
	& h4 {
		@extend %bold-48;
	}

	width: 100%;
	max-width: 700px;
	// text-align: right;
	margin-bottom: 36px;

	@include media('<laptop') {
		text-align: left;
	}
}

.about__image__figure {
	width: 100%;
	max-width: 700px;
	height: auto;

	aspect-ratio: 1;
	border-radius: 2px;
	overflow: hidden;
	position: relative;

	@include media('<laptop') {
		margin: 24px 0;
	}
}
.about__video__container {
	--container-width: 1080px;
	display: flex;
	flex-direction: row;
	margin: 64px 0;
	width: 100%;
	max-width: 1080px;
	padding: 0 0;

	@include media('<laptop') {
		margin: 24px 0;
	}

	@include media('<tablet') {
		flex-direction: column;

		.about__video__figure {
			display: none;

			&:first-of-type {
				display: block;
			}
		}
	}
}

.about__video {
	border-radius: 3%;
	position: relative;
	max-width: var(--container-width);

	&:hover {
		cursor: pointer;
		.about__video-overlay {
			background: rgba(0, 0, 0, 0.5);
		}
	}

	&.active:hover {
		cursor: default;
		.about__video-overlay {
			background: rgba(0, 0, 0, 0);
		}
	}

	@include media('>tablet') {
		transform: scale(0.5);
	}
	@include media('<phone') {
		max-width: 100%;
	}
}

.about__video-overlay {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0);
	transition: background 0.5s ease;
}

.about__image {
	width: 100%;
	border-radius: inherit;

	transform: scale(1.1);
	top: 0;
	left: 0;
	position: absolute;

	@include media('<phone') {
		transform: scale(1.5);
	}
}
.about__bottom__titles--right {
	// @include media('<laptop') {
	// 	text-align: left;
	// }
	// @include media('<phone') {
	// 	text-align: right;
	// }
	text-align: center;
}

.about__bottom__titles--left {
	text-align: center;
}
