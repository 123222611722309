.brandiden {
	flex-direction: column;
	padding: 0 0;
	padding-top: 0;

	.section__wrapper {
		z-index: z('content');
	}

	@include media('<phone') {
		padding-bottom: 50px;
	}
}

.brandiden__top__wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;

	width: 100%;
	height: inherit;

	color: rgb(var(--data-color-black));
	padding: var(--data-page-pad);
}
.brandiden__top__description {
	align-self: flex-start;
	max-width: 850px;
}
.brandiden__list {
	@extend %center;

	width: 100%;
	max-width: 100%;
	flex-direction: column;
	height: inherit;
	width: 100%;
	z-index: z('content');
	position: relative;
	padding-bottom: 96px;
}

// Item
.brandiden__item__wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	mix-blend-mode: screen;

	// background: rgb(var(--data-color-background));
	z-index: 1;

	width: inherit;
	border-bottom: 1px solid #1f1f1f;
	text-align: left;
	padding: var(--data-page-pad);
	padding-top: 0;
	padding-bottom: 0;
}
.brandiden__item {
	width: 100%;
	max-width: 1500px;
	margin: auto;
	// text-align: left;
	// padding-left: 12%;
	z-index: 1;

	&:hover {
		// .brandiden__item__title {
		// 	font-weight: 700;
		// }
	}
}

.brandiden__item__title {
	@extend %paragraph-20;
	padding: 24px;
	height: 100%;
	display: block;
	margin-bottom: 0;
}
.brandiden__preview {
	max-height: 400px;
	height: 100%;
	width: 100%;
	padding: 24px;

	img {
		border-radius: 24px;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include media('<phone') {
		padding: 0;
	}
}
