.get__started {
	display: flex;
	width: max-content;
	position: absolute;
	left: 0;
	bottom: 0;
	cursor: pointer;
	background: rgb(246, 244, 242);
	transition: background 0.5s ease;
}
.get__started:hover {
	background: rgb(var(--data-color-black));
}
.get__started:hover > .get__started__flex span {
	// background: rgb(var(--data-color-white));
	color: rgb(var(--data-color-white));

	&:nth-child(4n + 3),
	&:nth-child(4n + 4) {
		-webkit-text-fill-color: rgb(var(--data-color-black));
		-webkit-text-stroke-width: 1.2px;
		-webkit-text-stroke-color: rgb(var(--data-color-white));
	}
}
.get__started__flex {
}
.get__started__flex span {
	color: rgb(var(--data-color-black));
	margin-left: 24px;
	// @extend %bold-48;
	// font-family: 'Halenoir Light';
	font-family: 'Manrope';
	font-weight: 300;
	font-size: 36px;
	text-transform: uppercase;
	letter-spacing: 7px;
	transition: all 0.5s ease;

	&:nth-child(4n + 3),
	&:nth-child(4n + 4) {
		-webkit-text-fill-color: rgb(var(--data-color-background));
		-webkit-text-stroke-width: 1.2px;
		-webkit-text-stroke-color: rgb(var(--data-color-black));
	}
}
