.present {
	@extend %center;
}

.present__wrapper {
	@extend %center;

	height: inherit;
	width: 100%;
}
.present__box {
	@extend %center;

	min-width: 800px;
	max-width: 45vw;
	width: 100%;

	height: inherit;
	min-height: 45vh;

	background: rgb(253, 211, 174);
	border-radius: 20px;

	position: relative;
	overflow: hidden;

	@include media('<phone') {
		min-width: 100%;
	}
}

.present__text {
	z-index: 1;
	color: rgb(var(--data-color-white));
}
.present__title {
	@extend %extrabold-128;
	margin-left: -6px;
}
.present__subtitle {
	font-size: 24px;
}

.present__box__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: inherit;
	width: inherit;

	& img {
		object-fit: cover;
		height: inherit;
		width: inherit;
	}
}
.present__box__overlay {
	background: rgba(1, 1, 1, 0.78);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;
}
