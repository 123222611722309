.preloader {
	@extend %center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #fff;
	z-index: z('preloader');
}

.preloader__icon {
	position: relative;
}
.preloader__svg path,
.preloader__svg rect {
	& svg path,
	& svg rect {
		fill: rgb(var(--data-color-black));
	}
}
.preloader__svg--overlay {
	position: absolute;
	top: 0;
	left: 0;
	animation: reveal 2s forwards infinite;
	animation-delay: 1s;
	clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
}

@keyframes reveal {
	0% {
		clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
	}
	50% {
		clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
	}
	100% {
		clip-path: polygon(100% 0, 100% 100%, 100% 100%, 100% 0);
	}
}
