.selection {
	@extend %center;
	flex-direction: column;
	width: 100%;

	position: relative;
	z-index: z('content');
	height: 100%;
	min-height: 100vh;
	padding: 100px 0px;
	overflow: hidden;

	background: rgb(var(--data-color-background));
}

.selection__wrapper {
	@extend %center;
	flex-direction: column;
	height: inherit;
	width: inherit;
	padding-top: 20%;

	@include media('<tablet') {
		flex-direction: column;
	}

	@include media('<phone') {
		min-height: fit-content;
	}
}

.selection__figure {
	z-index: 0;

	position: absolute;
	top: 0;
	left: 0;

	min-height: 350px;
	min-width: 600px;
	border-radius: 4px;

	overflow: hidden;
}
.selection__image-container {
	height: 100%;
	display: flex;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		max-height: 1200px;
	}
}
.selection__image--hidden {
	display: none;
}

.selection__image {
	width: 600px;
	position: relative;
	top: 0;
	left: 0;
	transform: scale(1.5);
}
.selection__image--active {
	display: block;
}
.selection__item {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	flex: 1;
	box-sizing: border-box;
	// padding: 0 7vw;

	width: 100%;
	height: inherit;
	min-height: inherit;

	box-sizing: border-box;

	@include media('<tablet') {
		width: 100%;
		padding: 0 0;

		flex-direction: column-reverse;
	}
	&:nth-child(2) {
		@include media('<tablet') {
			flex-direction: column;
			margin-top: 64px;
		}
	}
}
.selection__left,
.selection__right {
	width: 100%;
	max-width: 50%;

	@include media('<tablet') {
		max-width: 100%;
	}
}
.selection--textcontent {
	@extend %center;
	flex-direction: column;
	padding: 7vw;

	@include media('<tablet') {
		margin-top: 36px;
		padding: 0 20px;
	}
}

.selection__title {
	@extend %extrabold-128;
	max-width: 700px;
	text-align: center;
	margin-bottom: 60px;

	@include media('<tablet') {
		margin-bottom: 20px;
	}
}

.selection__subtitle {
	display: grid;
	gap: 28px;
	width: 100%;
	max-width: 700px;
	grid-template-columns: repeat(3, 1fr);

	span {
		@extend %paragraph-16;
		margin-bottom: 0;
		text-align: center;
		color: rgba(1, 1, 1, 0.5);
		background: rgba(1, 1, 1, 0.05);
		padding: 10px 0;
		border-radius: 24px;
	}

	@include media('<phone') {
		gap: 12px;
	}
}
.selection__description {
	@extend %paragraph-20;

	max-width: 700px;

	margin-top: 48px;
	text-align: center;
	margin-bottom: 24px;

	@include media('>tablet') {
		min-height: 144px;
	}

	@include media('<tablet') {
		margin-top: 24px;
		margin-bottom: 64px;
	}
}

.selection__button {
	margin-top: 0;
	// @extend %center;
	// @extend %extrabold-128;
	// font-family: 'Space Mono';
	// box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	// border-radius: 20px;
	// width: 100%;
	// height: 100px;
	// max-width: 700px;

	// font-size: 20px;
	// letter-spacing: 0px;
	// color: rgba(var(--data-color-white), 1);
	mix-blend-mode: multiply;

	z-index: 1;
	// @include media('<tablet') {
	// 	color: rgba(var(--data-color-white), 1);
	// 	font-size: 1.2rem;
	// 	letter-spacing: -2px;
	// }
}

.selection__button--website:hover {
	background: linear-gradient(343deg, #d6c9a7, #d6b3a7);
}
.selection__button--design:hover {
	background: linear-gradient(17deg, #a4c3db, #a4aedb);
}
