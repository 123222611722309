@keyframes SlideBackground {
	0% {
		background-position: -100% 0;
	}
	100% {
		background-position: 100% 0;
	}
}

%slide-background {
	// background: linear-gradient(90deg, rgb(var(--data-color-black)), rgba(var(--data-color-black), 0.7), rgb(var(--data-color-black)));
	background-size: 200% auto;
	animation: SlideBackground 10s infinite linear;
}
	