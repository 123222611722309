@import './form';

.contact {
	overflow: hidden;
}

.contact .noisy__overlay__bg--gradientLinear {
	background: linear-gradient(
		to top,
		rgb(var(--data-grad-color), 1),
		rgb(var(--data-grad-color), 0.5)
	);
}
.contact section {
	color: rgb(var(--data-color-black));
	padding: 0 0;
	height: 100%;
	background: rgb(var(--data-color-background));
}

.contact__wrapper {
	display: grid;
	height: 100%;
	grid-template-columns: 33.25% 67.75%;
	align-items: start;

	@include media('<tablet') {
		display: flex;
		align-items: flex-start;
		justify-content: space-evenly;
		flex-direction: column;
	}
}

.contact__left {
	z-index: z('content');
	padding: 0 67px;
	padding-top: 20vh;
	min-height: 100%;
	background: rgb(var(--data-color-background));
	position: relative;

	@include media('<tablet') {
		min-width: 100%;
		min-height: 0vh;
		padding-bottom: 12vh;
	}
	@include media('<phone') {
		padding: 0 24px;
		padding-top: 96px;
	}
}

.contact__title {
	text-align: left;
	margin-left: 0;
	margin-bottom: 15px;
}
.contact__subtitle {
	@extend %paragraph-16;
	margin-bottom: 0;
	font-family: 'Space Mono', Arial, Helvetica, sans-serif;

	a {
		@extend %link--hidden;
	}
}

.contact__right {
	@include media('<tablet') {
		min-width: 100%;
	}
}
