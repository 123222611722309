.faq {
	@extend %page;
	@extend %center;
	flex-direction: column;
	min-height: fit-content;
	background: #f6f4f2;
	padding-top: 20vh;
	padding-bottom: 30vh;
}
.faq__wrapper {
	display: flex;
	flex-direction: column;
	z-index: z('content');
}
.faq__heading {
	@extend %bold-48;
	margin-bottom: 86px;
	text-align: center;
}
.faq__element__container {
	display: flex;
	flex-direction: column;
}
.faq__element {
	display: flex;
	flex-direction: row;
	width: 100%;
	border-bottom: 1px solid rgb(var(--data-color-black));
	margin-top: 96px;
	cursor: pointer;
	&:first-of-type {
		margin-top: 0;
	}
}
.faq__element__left {
	width: 80%;
}
.faq__element__right {
	display: flex;
	width: 20%;
	justify-content: flex-end;
	align-items: flex-start;
}
.faq__element__title {
	@extend %bold-20;
	text-align: left;
}
.faq__element__description {
	@extend %paragraph-16;
	margin-top: 24px;
	max-height: 0;
	overflow: hidden;
}
.faq__element__notes {
	@extend %paragraph-faded-small;
	margin-top: 24px;
}
.faq__element__svg {
	transform: rotate(180deg);
}
