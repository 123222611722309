%page {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 100vh;

	z-index: z('content');
}

%cover {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	object-fit: cover;
}

%center {
	display: flex;
	align-items: center;
	justify-content: center;
}

%absolute {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

%hidden {
	visibility: hidden;
	// opacity: 0;
}
