%link__wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
}

%link__line {
	content: '';
	position: absolute;
	bottom: 0rem;
	left: 0;
	width: 100%;
	height: 1px;
	background: currentColor;
	transition: transform 0.7s $ease-out-expo;
}

%link__line--100 {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	background: currentColor;
	transition: transform 0.7s $ease-out-expo;
}

%link__line--visible {
	transform: scaleX(1);
	transform-origin: left center;
}

%link__line--hidden {
	transform: scaleX(0);
	transform-origin: right center;
}

%link {
	@extend %link__wrapper;

	display: inline-block;

	&:after {
		@extend %link__line;
		@extend %link__line--visible;
	}

	&:hover {
		&:after {
			@extend %link__line--hidden;
		}
	}
}

%link--hidden {
	@extend %link__wrapper;

	display: inline-block;

	&:after {
		@extend %link__line;
		@extend %link__line--hidden;
	}

	&:hover {
		&:after {
			@extend %link__line--visible;
		}
	}
}

%link--hidden--100 {
	@extend %link__wrapper;

	display: inline-block;

	&:after {
		@extend %link__line--100;
		@extend %link__line--hidden;
	}

	&:hover {
		&:after {
			@extend %link__line--visible;
		}
	}
}
