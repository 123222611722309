@import './projects.scss';
.websites section {
	color: rgb(var(--data-color-black));
}
.websites .present {
	background: rgb(var(--data-color-white));
}
.websites .projects {
	background: #f6f4f2;
}
