.vis {
	@extend %page;
	
	background: rgba(var(--data-color-white));
	padding: 0 0;
	overflow: hidden;
	@include media('<tablet') {
		padding: 0 0;
	}
}
.vis__container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	transform: scale(1.5) rotate(-10deg);
}
.vis__row {
	display: flex;
	flex-direction: row;
}

.vis__item-side {
	opacity: 0.5;
}