@import './present';
@import './process';
@import './brandiden';
@import './graphicdes-1';
@import './vision';
@import './mdesign';

.designs section {
	color: rgb(var(--data-color-black));
	background: rgb(var(--data-color-background));
}
.designs {
	.pin-spacer {
		.vision, .process {
			max-height: 100% !important;
			height: 100% !important;
		}
	}
}
.designs .vision, .designs .mdesign, .designs .brandiden {
	background: #f6f4f2;
}
